import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import shopifyStyles from "./Shop/shopifyStyles";

const ShopifyBuyButton = ({
  domain,
  storefrontAccessToken,
  productId,
  nodeId,
}) => {
  useEffect(() => {
    const scriptURL =
      "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";

    const loadScript = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = scriptURL;
      document.head.appendChild(script);
      script.onload = ShopifyBuyInit;
    };

    const ShopifyBuyInit = () => {
      const client = window.ShopifyBuy.buildClient({
        domain,
        storefrontAccessToken,
      });

      window.ShopifyBuy.UI.onReady(client).then((ui) => {
        ui.createComponent("product", {
          id: productId,
          node: document.getElementById(nodeId),
          moneyFormat: "%24%7B%7Bamount%7D%7D",
          options: {
            product: {
              styles: {
                product: shopifyStyles.product,
                title: shopifyStyles.title,
                button: shopifyStyles.button,
                quantityInput: shopifyStyles.quantityInput,
                price: shopifyStyles.price,
                compareAt: shopifyStyles.compareAt,
                unitPrice: shopifyStyles.unitPrice,
              },
              buttonDestination: "modal",
              contents: {
                options: false,
              },
              text: {
                button: "View product",
              },
            },
            productSet: shopifyStyles.productSet,
            modalProduct: shopifyStyles.modalProduct,
            modal: shopifyStyles.modal,
            cart: shopifyStyles.cart,
            toggle: shopifyStyles.toggle,
          },
        });
      });
    };

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }
  }, [domain, storefrontAccessToken, productId, nodeId]);

  return <div id={nodeId}></div>;
};

const RoostItem = ({
  rec_domain,
  rec_storefrontAccessToken,
  rec_productId,
  rec_nodeId,
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#E8A4C3",
        borderRadius: "20px",
        padding: "20px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "400px",
        width: "100%",
        textAlign: "center",
        margin: 10,
      }}
    >
      <div className="product">
        <ShopifyBuyButton
          domain={rec_domain}
          storefrontAccessToken={rec_storefrontAccessToken}
          productId={rec_productId}
          nodeId={rec_nodeId}
        />
      </div>
    </div>
  );
};

function Shop() {
  const [dir, setDir] = useState("row");

  useEffect(() => {
    if (isMobile) {
      setDir("column");
    }
  }, []);

  return (
    <div
      className="contact"
      style={{
        paddingBottom: 100,
        backgroundColor: "#f7c9e5",
        display: "flex",
        textAlign: "center",
        height: 1500

      }}
    >
      <Helmet>
        <title>Shop - roostgame.com</title>
        <meta
          name="description"
          content="Browse the Roost Game Shop. Get a copy of Roost now!"
        />
      </Helmet>
      <div style={{ width: "100%" }}>
        <div
          style={{
            paddingLeft: 0,
          }}
        >
          <div
            style={{
              paddingBottom: 100,
              flexDirection: "row",
              paddingTop: 20,
              fontFamily: "Gotham, sans-serif",
              fontSize: "40px",
              fontWeight: "lighter",
              color: "#ed1a84",
            }}
          >
            <h1>
              <b>Our Games</b>
            </h1>
            <p
              style={{
                fontFamily: "Roberto, sans-serif",
                fontSize: "14px",
                color: "#ed1a84",
              }}
            >
              <b>All prices are in AUD ($)</b>
            </p>
            {ShopItems()}
          </div>
        </div>
      </div>
    </div>
  );

  function ShopItems() {  // Rename shopItems to ShopItems
    const [loading, setLoading] = useState(true);
  
    // Simulate loading data (you can replace this with real data fetching)
    useEffect(() => {
      setTimeout(() => {
        setLoading(false); // After 3 seconds, stop loading
      }, 1500);
    }, []);
    const spinnerStyle = {
      border: "4px solid #f3f3f3", // Light grey background
      borderTop: "4px solid #ed1a84", // Blue top
      borderRadius: "50%",
      width: "40px", // Size of the spinner
      height: "40px",
      animation: "spin 2s linear infinite", // Spinning animation
    };
  
  
    // Keyframe animation is handled in the inline style for the spinner
    const keyframes = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    return (
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: dir
        }}
      >
        {loading ? (
          <>
          <style>{keyframes}</style>
          <div style={spinnerStyle}></div> 
          </>
        ) : (
          <>
            <RoostItem
              rec_domain="9bae23-23.myshopify.com"
              rec_storefrontAccessToken="8cf9982a6baef5b25406229e691f27e9"
              rec_productId="8523599479000"
              rec_nodeId="product-component-1724549626246"
            />
            <RoostItem
              rec_domain="9bae23-23.myshopify.com"
              rec_storefrontAccessToken="8cf9982a6baef5b25406229e691f27e9"
              rec_productId="8523611537624"
              rec_nodeId="product-component-1724551316802"
            />
          </>
        )}
      </div>
    );
  }
}

export default Shop;
