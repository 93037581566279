import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import cards from "../images/LandingPage/landingpage.png";
import cards_mobile from "../images/LandingPage/landingpagemobile.png";
import logo from "../images/LandingPage/logo.png";
import { PurchaseModuleSimple } from "./PurchaseModules";
import { isMobile } from "react-device-detect";
import "photoswipe/dist/photoswipe.css";
import { SwipeModule } from "./landing_page";
import gif from "../images/mmcard.png";

import { PageStyles } from "./landing_page/LandingPageStyles";

function LandingPage() {
  const [back_img, setBackImg] = useState(cards);
  const [posX, setPosX] = useState("50%");
  const [posY, setPosY] = useState("73.2%");
  const [widthMob, setWidthMob] = useState("");
  const [videoWidth, setVideoWidth] = useState("40%");
  const [videoHeight, setVideoHeight] = useState(400);

  const [mobPad, setMobPad] = useState("0");
  const [mPaddingBot, setMPaddingBot] = useState(50);

  const [positionX, setPositionX] = useState(10);
  const [positionY, setPositionY] = useState(25);
  const [rotation, setRotation] = useState(0);
  const [directionX, setDirectionX] = useState(1);
  const [directionY, setDirectionY] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update horizontal position
      setPositionX((prev) => {
        if (prev >= 35) {
          setDirectionX(-1); // Change direction to left
        } else if (prev <= 0) {
          setDirectionX(1); // Change direction to right
        }
        return prev + directionX * 3; // Change the bounce speed
      });

      // Update vertical position
      setPositionY((prev) => {
        if (prev >= 40) {
          setDirectionY(-1); // Change direction to up
        } else if (prev <= 15) {
          setDirectionY(1); // Change direction to down
        }
        return prev + directionY; // Change the bounce speed
      });

      setRotation((prev) => prev + 4); // Rotate image
    }, 30); // Change the interval to control animation speed

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [directionX, directionY]);

  useEffect(() => {
    if (isMobile) {
      setVideoWidth("90%");
      setBackImg(cards_mobile);
      setPosX("165%");
      setPosY("50%");
      setWidthMob("80%");
      setMobPad(500);
      setMPaddingBot(100);
      setVideoHeight(250);
    }
  }, []);

  const videoUnbox = (
    <div style={{ ...PageStyles.unboxing_div, paddingBottom: mPaddingBot }}>
      <iframe
        width={videoWidth}
        height={videoHeight} // Adjust height to your needs
        src="https://www.youtube.com/embed/9qXPUQvhqoA" // Replace VIDEO_ID with the actual YouTube video ID
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={PageStyles.youtube_style}
      ></iframe>
    </div>
  );
  return (
    <div style={PageStyles.box}>
      <Helmet>
        <title>LandingPage - roostgame.com</title>
        <meta name="description" content="Landing Page for Roost" />
      </Helmet>

      <div style={PageStyles.overlay_container}>
        <img src={back_img} alt="" style={PageStyles.img_top} />
        <div
          style={{
            ...PageStyles.img_pos,
            top: posX,
            left: posY,
            width: widthMob,
          }}
        >
          <img src={logo} alt="" style={PageStyles.img_style} />
          <p style={PageStyles.blurb_text}>
            birds, memory, strategy, speed, alliances and more <b>birds</b>{" "}
          </p>
          <PurchaseModuleSimple />
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f7c9e5",
          marginTop: mobPad,
        }}
      />
      {isMobile ? <>{bouncingBird()}</> : <div></div>}
      <div style={PageStyles.swipe_module} />
      <SwipeModule />
      <div style={PageStyles.below_swipe_module}></div>

      <div style={PageStyles.featured_photos}>
        <div style={PageStyles.bar_text}>Unboxing</div>
        {videoUnbox}
      </div>
    </div>
  );

  function bouncingBird() {
    return (
      <div style={PageStyles.bouncing_bird_container}>
        <img
          src={gif}
          style={{
            top: `${positionX}%`,
            left: `${positionY}%`,
            transform: `rotate(${rotation}deg)`,
            ...PageStyles.bouncing_bird,
          }}
          alt="Bouncing Bird"
        />
      </div>
    );
  }
}

export default LandingPage;
