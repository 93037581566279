const shopifyStyles = {
  product: {
    "@media (min-width: 601px)": {
      "max-width": "calc(25% - 20px)",
      "margin-left": "20px",
      "margin-bottom": "50px",
    },
  },
  title: {
    "font-weight": "normal",
    "font-family": "Roberto, sans-serif",
    "font-size": "20px",
    color: "#000000",
  },
  button: {
    "font-size": "14px",
    "font-family": "Roberto, sans-serif",
    "padding-top": "17px",
    "padding-bottom": "17px",
    color: "#ffffff",
    ":hover": {
      color: "#ffffff",
      "background-color": "#D80068",
    },
    "background-color": "#EC2383",
    ":focus": {
      "background-color": "#D80068",
    },
    "border-radius": "40px",
    "padding-left": "53px",
    "padding-right": "53px",
  },
  quantityInput: {
    "font-size": "18px",
    "padding-top": "17px",
    "padding-bottom": "17px",
  },
  price: {
    "font-family": "Roberto, sans-serif",
    "font-size": "14px",
    color: "#000000",
  },
  compareAt: {
    "font-family": "Roberto, sans-serif",
    "font-size": "15px",
    color: "#D80068",
  },
  unitPrice: {
    "font-family": "Roberto, sans-serif",
    "font-size": "15px",
    color: "#29cfcf",
  },
  productSet: {
    styles: {
      products: {
        "max-width": "calc(25% - 20px)",
        "margin-left": "20px",
        "margin-bottom": "50px",
      },
    },
  },
  modalProduct: {
    contents: {
      img: false,
      imgWithCarousel: true,
      button: false,
      buttonWithQuantity: true,
    },
    styles: {
      product: {
        "max-width": "100%",
        "margin-left": "0px",
        "margin-bottom": "0px",
      },
      button: {
        "font-size": "18px",
        "padding-top": "14px",
        "padding-bottom": "9px",
        "background-color": "#D80068",
        "border-radius": "40px",
        "padding-left": "53px",
        "padding-right": "53px",
        ":hover": {
          "background-color": "#D80068",
        },
        ":focus": {
          "background-color": "#D80068",
        },
      },
      title: {
        "font-family": "Roberto, sans-serif",
        "font-weight": "bold",
        "font-size": "27px",
        color: "#000000",
      },
      price: {
        "font-family": "Roberto, sans-serif",
        "font-weight": "normal",
        "font-size": "17px",
        color: "#000000",
      },
      compareAt: {
        "font-family": "Roberto, sans-serif",
        "font-weight": "normal",
        "font-size": "14px",
        color: "#000000",
      },
      unitPrice: {
        "font-family": "Roberto, sans-serif",
        "font-weight": "normal",
        "font-size": "14px",
        color: "#000000",
      },
      description: {
        color: "#000000",
      },
    },
    text: {
      button: "Add to cart",
    },
  },
  modal: {
    styles: {
      modal: {
        "background-color": "#f7c9e5",
      },
    },
  },
  cart: {
    styles: {
      button: {
        "font-size": "18px",
        "padding-top": "17px",
        "padding-bottom": "17px",
        "background-color": "#EC2383",
        "border-radius": "40px",
        ":hover": {
          "background-color": "#D80068",
        },
        ":focus": {
          "background-color": "#D80068",
        },
      },
    },
    text: {
      total: "Subtotal",
      button: "Checkout",
    },
    contents: {
      note: true,
    },
  },
  toggle: {
    styles: {
      toggle: {
        "background-color": "#EC2383",
        ":hover": {
          "background-color": "#D80068",
        },
        ":focus": {
          "background-color": "#D80068",
        },
      },
      count: {
        "font-size": "18px",
      },
    },
  },
};

export default shopifyStyles;
