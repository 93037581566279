import React from "react";
import {
  AiFillInstagram,
  AiFillYoutube,
  AiFillTwitterCircle,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { colours } from "./Styles";

const iconLinkStyle = {
  paddingLeft: 5,
  paddingRight: 5,
  color: colours.button_filling,
};

const SocialLink = ({ href, icon }) => (
  <a href={href} target="_blank" style={iconLinkStyle} rel="noreferrer">
    {icon}
  </a>
);

function Footer() {
  // Determine the screen size
  const isMobile = window.innerWidth <= 576;

  // Define styles based on screen size
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: isMobile ? "column" : "row", // Switch to column on mobile
    textAlign: isMobile ? "center" : "left",
  };

  const itemStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  };

  return (
    <div className="footer">
      <footer
        className="py-3 fixed-bottom"
        style={{
          backgroundColor: "#EC2383",
        }}
      >
        <div className="container" style={containerStyle}>
          <div style={itemStyle}>
            <a
              className="e-widget no-button"
              href="/enquiries"
              rel="nofollow"
              style={{
                color: "#000",
                textDecoration: "none",
                margin: "0 10px 0 0",
              }}
            >
              <p className="m-0 text-white">Enquiries</p>
            </a>
            <a
              className="e-widget no-button"
              href="/privacypolicy"
              rel="nofollow"
              style={{ color: "#000", textDecoration: "none", margin: 0 }}
            >
              <p className="m-0 text-white">Privacy Policy</p>
            </a>
          </div>
          <div style={{ textAlign: isMobile ? "center" : "left" }}>
            <p className="m-0 text-white">&copy; 2024, ROOST CO</p>
          </div>
          <div style={itemStyle}>
            <SocialLink
              href="https://www.instagram.com/roostgame/"
              icon={<AiFillInstagram />}
            />
            <SocialLink
              href="https://www.youtube.com/@Roostgame"
              icon={<AiFillYoutube />}
            />
            <SocialLink
              href="https://www.tiktok.com/@roostgame?_t=8e8xO22xcvQ&_r=1"
              icon={<FaTiktok />}
            />
            <SocialLink
              href="https://twitter.com/RoostCardGame"
              icon={<AiFillTwitterCircle />}
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
