const PageStyles = {
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f7c9e5",
  },
  overlay_container: {
    position: "relative",
    width: "100%",
    maxWidth: "1500px",
    margin: "0 auto",
  },
  img_top: {
    flex: 1,
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  img_pos: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    color: "black",
    fontSize: "24px",
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
    zIndex: 1,
  },
  img_style: {
    flex: 1,
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  unboxing_div: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  bar_text: {
    padding: 10,
    justifyContent: "center",
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
    fontSize: "26px",
    color: "#ed1a84",
    alignItems: "center",
  },
  blurb_text: {
    paddingTop: 10,
    color: "#ed1a84",
    margin: 0,
    paddingBottom: 10,
  },
  bouncing_bird: {
    position: "absolute",
    transition: "top 0.1s",
    width: "200px",
    maxHeight: "100%",
    objectFit: "contain",
    backgroundColor: "transparent",
  },
  bouncing_bird_container: {
    width: "100%",
    height: "10vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start", // Align items to the top
    position: "relative",
  },
  below_swipe_module: {
    backgroundColor: "#f7c9e5",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 40,
  },
  featured_photos: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 20,
    width: "100%",
  },
  youtube_style: {
    marginLeft: "10px", // Optional: Add spacing between elements
    flexShrink: 0,
  },
  swipe_module: {
    backgroundColor: "#f7c9e5",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default PageStyles ;
