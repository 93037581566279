import React, { useState, useEffect } from "react";
import rich_rooster from "../images/newbird.png";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { colours, font_families } from "./Styles";
import "./NotificationAnimation.css"; // Import the CSS file with animations
import { PurchaseModule } from "./PurchaseModules";

const NonStickyNotificationBarCool = (props) => {
  const [showNotification] = useState(true);

  const notificationStyle = {
    justify: "flex",
    display: showNotification ? "block" : "none",

    width: props.mobile ? "90%" : "100%",
    backgroundColor: "#fff",
    color: colours.roost_pink,

    zIndex: 999, // Ensure it's above other elements
    padding: 20,
    borderRadius: "10px", // Rounded corners
    border: "2px solid " + colours.roost_pink, // White outline
  };

  const text = "Sign up to our mailing list for a free Rich Rooster card*";

  const [highlightIndex, setHighlightIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 5) % text.length); // Cycle through characters
    }, 200); // Adjust timing for the speed of the highlight
    return () => clearInterval(interval); // Clean up on component unmount
  }, [text]);

  return (
    <div style={notificationStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <img
          src={rich_rooster}
          alt="Notification icon"
          style={{ width: "70px", marginRight: "30px" }}
        />
        <a
          className="e-widget no-button"
          href="/shop"
          rel="nofollow"
          style={{ color: "#000", textDecoration: "none", margin: 0 }}
        >
          <div>
            <p style={{ margin: 0, fontWeight: "bold", fontSize: 20 }}>
              {" "}
              Rich Rooster{" "}
            </p>
            <p style={{ fontSize: "10px" }}>
              {text.split("").map((char, index) => (
                <span
                  key={index}
                  style={{
                    color:
                      index >= highlightIndex && index < highlightIndex + 5
                        ? "red"
                        : "black", // Highlight 5 characters
                    transition: "color 0.2s ease-in-out", // Smooth color transition
                  }}
                >
                  {char}
                </span>
              ))}
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

function Purchase() {
  const [direction, setDirection] = useState("row");
  const [mobileDevice, setMobileDevice] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setDirection("column");
      setMobileDevice(true);
    }
  }, []);

  return (
    <div
      className="about"
      style={{
        paddingBottom: 100,
        fontFamily: font_families.roost,
        backgroundColor: "#f7c9e5",
      }}
    >
      <Helmet>
        <title>Community - Roost Game</title>
        <meta name="description" content="Join the Roost community" />
      </Helmet>

      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Gotham, sans-serif",
            fontWeight: "lighter",
            color: "#ed1a84",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
          }}
        >
          <h1>
            <b>Perch the Roost...</b>
          </h1>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              margin: 20,
              borderRadius: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: 20,
              textAlign: "center",
              backgroundColor: "#FFF",
            }}
          >
            <p style={{ fontWeight: "350", paddingBottom: 0 }}>
              <i>Stay in the loop by joining the mailing list</i>
            </p>

            <PurchaseModule />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: 10,
                width: "100%",
                padding: 10,
              }}
            >
              <NonStickyNotificationBarCool mobile={mobileDevice} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            ></div>
          </div>
        </div>
      </div>

      <div style={{ padding: 20, marginTop: 30 }}>
        <p style={{ fontSize: 10 }}>
          * You must purchase a copy of Roost to obtain the Rich Rooster card.
          Rich Rooster will be delivered with your copy of Roost if purchased
          via this website.
        </p>
      </div>
    </div>
  );
}

export default Purchase;
