import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import { Helmet } from "react-helmet";
import { API } from "aws-amplify";
import { RoostButton } from "./Modals";
const myAPI = "Contact";
const otherAPI = "mailchimp";

function Contact() {
  const [direction, setDirection] = useState("row");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [error, setError] = React.useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  function handleChangeEmail(event) {
    setEmail(event.target.value);
  }

  function handleChangeFirstName(event) {
    setFirstName(event.target.value);
  }

  function handleChangeLastName(event) {
    setLastName(event.target.value);
  }
  function handleChangeSubject(event) {
    setSubject(event.target.value);
  }
  function handleChangeDescription(event) {
    setDescription(event.target.value);
  }

  //Function to fetch from our backend and update customers array
  function addEmail() {
    if (email === "") {
      setError("Please enter an email address");
      return;
    }
    if (isChecked === true) {
      let data = { firstName: firstName, lastName: lastName, email: email };
      API.post(otherAPI, "/mailchimp", { body: data })
        .then((response) => {
          console.log(response);
          setError("Thankyou for joining the waitlist!");
        })
        .catch(() => {
          setError("There was an error with joining the waitlist");
        });
    }

    let data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      description: description,
      subject: subject,
    };

    API.post(myAPI, "/Contact", { body: data })
      .then((response) => {
        console.log(response);
        setError("Message Sent");
      })
      .catch(() => {
        console.log("Error");
        setError("There was an error with sending your message");
      });
  }

  useEffect(() => {
    if (isMobile) {
      setDirection("column");
    }
  }, []);

  return (
    <div style={{ paddingBottom: 200, backgroundColor: "#f7c9e5" }}>
      <Helmet>
        <title>Contact - roostgame.com</title>
        <meta
          name="description"
          content="Contact our team using the following links"
        />
      </Helmet>
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: direction,
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Gotham, sans-serif",
            fontWeight: "lighter",
            color: "#ed1a84",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: direction,
              justifyContent: "center",

              fontFamily: "Gotham, sans-serif",
              color: "#ed1a84",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  padding: 0,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    padding: 20,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h1>
                    <b>Contact Us</b>
                  </h1>
                  <p style={{ marginBottom: 0, fontWeight: "350" }}>
                    If you need to get in touch, use the following form! If you
                    are a retailer, please express your company name and phone
                    number in the description.
                  </p>
                  <label
                    style={{
                      marginBottom: 0,
                      width: isMobile ? "80%" : "60%",
                      display: "block",
                    }}
                  >
                    First Name:
                    <br></br>
                    <input
                      type="text"
                      value={firstName}
                      onChange={handleChangeFirstName}
                      style={{ width: "100%", boxSizing: "border-box" }}
                    />
                  </label>
                  <label
                    style={{
                      marginBottom: 0,
                      width: isMobile ? "80%" : "60%",
                      display: "block",
                    }}
                  >
                    Last Name:
                    <br></br>
                    <input
                      type="text"
                      value={lastName}
                      onChange={handleChangeLastName}
                      style={{ width: "100%", boxSizing: "border-box" }}
                    />
                  </label>
                  <label
                    style={{
                      marginBottom: 0,
                      width: isMobile ? "80%" : "60%",
                      display: "block",
                    }}
                  >
                    <b>*</b>Email:
                    <br></br>
                    <input
                      type="text"
                      value={email}
                      onChange={handleChangeEmail}
                      style={{ width: "100%", boxSizing: "border-box" }}
                    />
                  </label>
                  <label
                    style={{
                      marginBottom: 0,
                      width: isMobile ? "80%" : "60%",
                      display: "block",
                    }}
                  >
                    <b>*</b>Subject:
                    <br></br>
                    <input
                      type="text"
                      value={subject}
                      onChange={handleChangeSubject}
                      style={{ width: "100%", boxSizing: "border-box" }}
                    />
                  </label>
                  <label
                    style={{
                      marginBottom: 0,
                      width: isMobile ? "80%" : "60%",
                      display: "block",
                    }}
                  >
                    <b>*</b>Description:
                    <br></br>
                    <textarea
                      type="text"
                      value={description}
                      onChange={handleChangeDescription}
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        height: 100,
                      }}
                    />
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      className="checkbox"
                      htmlFor="newsletter"
                      style={{ margin: 0 }}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    </label>

                    <p
                      style={{
                        paddingLeft: 10,
                        textAlign: "center",
                        textAlignVertical: "center",
                        margin: 0,
                      }}
                    >
                      Do you want to sign up to the newsletter?
                    </p>
                  </div>
                  <br></br>
                  <p>
                    <i>{error}</i>
                  </p>
                  <RoostButton
                    text="Send Message"
                    action={addEmail}
                  ></RoostButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
