import React from "react";

import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const emailToCopy = "business@roostgame.com";

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(emailToCopy)
      .then(() => {})
      .catch(() => {});
  };
  return (
    <div style={{ paddingBottom: 100 }}>
      <Helmet>
        <title>Scorecard - roostgame.com</title>
        <meta
          name="description"
          content="The home of all Roost related updates"
        />
      </Helmet>
      <div className="container">
        <div style={{ width: "100%", padding: 5, paddingTop: 20 }}></div>
        <div>
          <h1>Roost Co Privacy Policy</h1>
          <p>Last updated: 25/8/2024</p>

          <p>
            This Privacy Policy describes how Roost Co (the &quot;Site&quot;,
            &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses,
            and discloses your personal information when you visit, use our
            services, or make a purchase from www.roostgame.com (the
            &quot;Site&quot;) or otherwise communicate with us (collectively,
            the &quot;Services&quot;). For purposes of this Privacy Policy,
            &quot;you&quot; and &quot;your&quot; means you as the user of the
            Services, whether you are a customer, website visitor, or another
            individual whose information we have collected pursuant to this
            Privacy Policy.
          </p>

          <p>
            Please read this Privacy Policy carefully. By using and accessing
            any of the Services, you agree to the collection, use, and
            disclosure of your information as described in this Privacy Policy.
            If you do not agree to this Privacy Policy, please do not use or
            access any of the Services.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time, including to
            reflect changes to our practices or for other operational, legal, or
            regulatory reasons. We will post the revised Privacy Policy on the
            Site, update the &quot;Last updated&quot; date and take any other
            steps required by applicable law.
          </p>

          <h2>How We Collect and Use Your Personal Information</h2>
          <p>
            To provide the Services, we collect and have collected over the past
            12 months personal information about you from a variety of sources,
            as set out below. The information that we collect and use varies
            depending on how you interact with us.
          </p>
          <p>
            In addition to the specific uses set out below, we may use
            information we collect about you to communicate with you, provide
            the Services, comply with any applicable legal obligations, enforce
            any applicable terms of service, and to protect or defend the
            Services, our rights, and the rights of our users or others.
          </p>

          <h3>What Personal Information We Collect</h3>
          <p>
            The types of personal information we obtain about you depends on how
            you interact with our Site and use our Services. When we use the
            term &quot;personal information&quot;, we are referring to
            information that identifies, relates to, describes, or can be
            associated with you. The following sections describe the categories
            and specific types of personal information we collect.
          </p>

          <h3>Information We Collect Directly from You</h3>
          <ul>
            <li>
              Basic contact details including your name, address, phone number,
              email.
            </li>
            <li>
              Order information including your name, billing address, shipping
              address, payment confirmation, email address, phone number.
            </li>
            <li>
              Account information including your username, password, security
              questions.
            </li>
            <li>
              Shopping information including the items you view, put in your
              cart, or add to your wishlist.
            </li>
            <li>
              Customer support information including the information you choose
              to include in communications with us, for example, when sending a
              message through the Services.
            </li>
          </ul>
          <p>
            Some features of the Services may require you to directly provide us
            with certain information about yourself. You may elect not to
            provide this information, but doing so may prevent you from using or
            accessing these features.
          </p>

          <h3>Information We Collect through Cookies</h3>
          <p>
            We also automatically collect certain information about your
            interaction with the Services (&quot;Usage Data&quot;). To do this,
            we may use cookies, pixels, and similar technologies
            (&quot;Cookies&quot;). Usage Data may include information about how
            you access and use our Site and your account, including device
            information, browser information, information about your network
            connection, your IP address, and other information regarding your
            interaction with the Services.
          </p>

          <h3>Information We Obtain from Third Parties</h3>
          <p>
            Finally, we may obtain information about you from third parties,
            including from vendors and service providers who may collect
            information on our behalf, such as:
          </p>
          <ul>
            <li>
              Companies who support our Site and Services, such as Shopify,
              Kickstarter and Mailchimp.
            </li>
            <li>
              Our payment processors, who collect payment information (e.g.,
              bank account, credit or debit card information, billing address)
              to process your payment in order to fulfill your orders and
              provide you with products or services you have requested, in order
              to perform our contract with you.
            </li>
            <li>
              When you visit our Site, open or click on emails we send you, or
              interact with our Services or advertisements, we, or third parties
              we work with, may automatically collect certain information using
              online tracking technologies such as pixels, web beacons, software
              developer kits, third-party libraries, and cookies.
            </li>
          </ul>
          <p>
            Any information we obtain from third parties will be treated in
            accordance with this Privacy Policy. We are not responsible or
            liable for the accuracy of the information provided to us by third
            parties and are not responsible for any third party&apos;s policies
            or practices. For more information, see the section below, Third
            Party Websites and Links.
          </p>

          <h3>How We Use Your Personal Information</h3>
          <ul>
            <li>
              <strong>Providing Products and Services.</strong> We use your
              personal information to provide you with the Services in order to
              perform our contract with you, including to process your payments,
              fulfill your orders, to send notifications to you related to you
              account, purchases, returns, exchanges, or other transactions, to
              create, maintain and otherwise manage your account, to arrange for
              shipping, facilitate any returns and exchanges, and to enable you
              to post reviews.
            </li>
            <li>
              <strong>Marketing and Advertising.</strong> We use your personal
              information for marketing and promotional purposes, such as to
              send marketing, advertising, and promotional communications by
              email, text message, or postal mail, and to show you
              advertisements for products or services. This may include using
              your personal information to better tailor the Services and
              advertising on our Site and other websites.
            </li>
            <li>
              <strong>Security and Fraud Prevention.</strong> We use your
              personal information to detect, investigate, or take action
              regarding possible fraudulent, illegal, or malicious activity. If
              you choose to use the Services and register an account, you are
              responsible for keeping your account credentials safe. We highly
              recommend that you do not share your username, password, or other
              access details with anyone else. If you believe your account has
              been compromised, please contact us immediately.
            </li>
            <li>
              <strong>Communicating with you.</strong> We use your personal
              information to provide you with customer support and improve our
              Services. This is in our legitimate interests in order to be
              responsive to you, to provide effective services to you, and to
              maintain our business relationship with you.
            </li>
          </ul>

          <h2>Cookies</h2>
          <p>
            Like many websites, we use Cookies on our Site. For specific
            information about the Cookies that we use related to powering our
            store with Shopify, see{" "}
            <a href="https://www.shopify.com/legal/cookies">
              Shopify Cookies Policy
            </a>
            . We use Cookies to power and improve our Site and our Services
            (including to remember your actions and preferences), to run
            analytics and better understand user interaction with the Services
            (in our legitimate interests to administer, improve and optimize the
            Services). We may also permit third parties and services providers
            to use Cookies on our Site to better tailor the services, products,
            and advertising on our Site and other websites.
          </p>
          <p>
            Most browsers automatically accept Cookies by default, but you can
            choose to set your browser to remove or reject Cookies through your
            browser controls. Please keep in mind that removing or blocking
            Cookies can negatively impact your user experience and may cause
            some of the Services, including certain features and general
            functionality, to work incorrectly or no longer be available.
            Additionally, blocking Cookies may not completely prevent how we
            share information with third parties such as our advertising
            partners.
          </p>

          <h2>How We Disclose Personal Information</h2>
          <p>
            In certain circumstances, we may disclose your personal information
            to third parties for legitimate purposes subject to this Privacy
            Policy. Such circumstances may include:
          </p>
          <ul>
            <li>
              With vendors or other third parties who perform services on our
              behalf (e.g., IT management, payment processing, data analytics,
              customer support, cloud storage, fulfillment, and shipping).
            </li>
            <li>
              With business and marketing partners, including Shopify, to
              provide services and advertise to you.
            </li>
            <li>
              When you direct, request us or otherwise consent to our disclosure
              of certain information to third parties, such as to ship you
              products or through your use of social media widgets or login
              integrations, with your consent.
            </li>
            <li>
              With our affiliates or otherwise within our corporate group, in
              our legitimate interests to run a successful business.
            </li>
            <li>
              In connection with a business transaction such as a merger or
              bankruptcy, to comply with any applicable legal obligations
              (including to respond to subpoenas, search warrants, or other
              legal process, or to establish or defend a legal claim), or where
              we believe it is necessary in our legitimate interests to protect
              our legal rights, property, or the safety of you or others.
            </li>
          </ul>

          <h3>Security</h3>
          <p>
            We implement and maintain reasonable security measures, such as
            access controls, encryption, and secure servers, designed to protect
            the personal information we maintain from unauthorized access,
            destruction, use, modification, or disclosure. However, no security
            system is perfect, and we cannot guarantee that your personal
            information will remain secure in all circumstances, including the
            security of your data transmitted to us or received from us over the
            internet.
          </p>

          <h2>Third-Party Websites and Links</h2>
          <p>
            Our Services may contain links to other websites or online services
            that are not operated by us. If you click on a third-party link, you
            will be directed to that third party&apos;s website. We strongly
            advise you to review the privacy policy of every site you visit. We
            are not responsible for the content, privacy policies, or practices
            of any third-party sites or services.
          </p>

          <h2>Children&apos;s Privacy</h2>
          <p>
            Our Services are not directed to individuals under the age of 16. If
            we learn that we have collected personal information from a child
            under age 16 without parental consent, we will delete that
            information as required by applicable law.
          </p>

          <h2>Your Rights</h2>
          <p>
            Depending on your location, you may have certain rights regarding
            your personal information. These rights may include:
          </p>
          <ul>
            <li>
              The right to access – You have the right to request copies of your
              personal information from us.
            </li>
            <li>
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate or complete
              information you believe is incomplete.
            </li>
            <li>
              The right to erasure – You have the right to request that we erase
              your personal information, under certain conditions.
            </li>
            <li>
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal information,
              under certain conditions.
            </li>
            <li>
              The right to object to processing – You have the right to object
              to our processing of your personal information, under certain
              conditions.
            </li>
            <li>
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </li>
          </ul>

          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us at our
            given contact information.
          </p>

          <h2>Contact Information</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, our
            practices, or our services, you can contact us at:
          </p>
          <ul>
            <li>
              Email:{" "}
              <span
                onClick={copyToClipboard}
                style={{ color: "green", cursor: "pointer" }}
              >
                business@roostgame.com
              </span>{" "}
            </li>
            <li>
              Address: 180 Lord St, Newtown, Newtown, NSW, 2042, Australia
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
