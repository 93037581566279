import React from "react";
import { isMobile } from "react-device-detect";
import { FaGamepad } from "react-icons/fa";
import { GiDirectorChair, GiCardRandom } from "react-icons/gi";
import { BiTestTube, BiFirstPage } from "react-icons/bi";
import { Helmet } from "react-helmet";
import { ContactScreen } from "./ContactScreen";

import personal from "../images/personal.png";

function Supporters() {
  const box_style = {
    padding: 10,
    borderColor: "#ffffff",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    color: "#ed1a84",
    border: "none",
    outline: "none",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: isMobile ? "90%" : "50%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "opacity 0.3s ease-in-out", // Transition for smooth fading
  };

  return (
    <div
      className="contact"
      style={{ paddingBottom: 100, backgroundColor: "#f7c9e5" }}
    >
      <Helmet>
        <title>Supporters - roostgame.com</title>
        <meta
          name="description"
          content="All supporters of the Roost Kickstarter Launch"
        />
      </Helmet>

      <div
        style={{
          paddingBottom: 100,
          flexDirection: "row",
          paddingTop: 20,
          fontFamily: "Gotham, sans-serif",
          fontWeight: "lighter",
          color: "#ed1a84",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1>
              <b>About Us</b>
            </h1>
            <p style={{ padding: 10, textAlign: "center", marginBottom: 0 }}>
              <i>
                It would have been impossible to fully design Roost without some
                help. I am forever grateful for the people who have supported me
                along this journey.{" "}
              </i>
            </p>
            <div style={{ marginBottom: 20 }}>
              <ContactScreen />
            </div>
            <div style={box_style}>
              <h2>Game Creator</h2>
              <ul>
                <li>Beau Hobba</li>
              </ul>

              <img
                src={personal}
                style={{
                  width: "50%",
                  padding: 7,
                  marginLeft: 5,
                  objectFit: "contain",
                }}
                alt="Personal"
              ></img>
              <p
                style={{
                  fontWeight: "lighter",
                  fontStyle: "italic",
                  margin: 10,
                  textAlign: "center",
                }}
              >
                I created Roost to serve as a meaningful platform for giving
                back and positively impacting the extraordinary world of birds.
                With my deep-rooted emphasis on conservation, Roost highlights
                the importance of preserving our natural environment. In my
                life, I try my best to support research into our aviary friends,
                and on the side, I am attempting to photograph every animal that
                makes up Australian wildlife! I hope this game inspires you to
                go outside and start your journey into admiring the beautiful
                creatures around you (even if they don't possess cool
                sunglasses).
              </p>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "black",
                  height: "2px",
                  opacity: "15%",
                }}
              />
            </div>

            <div style={{ ...box_style, margin: 10 }}>
              <h2>Early Helpers</h2>
              <p style={{ padding: 10, textAlign: "center" }}>
                These people helped with game and product design
              </p>
              <ul>
                <li>
                  <FaGamepad />
                  {"\t"}Julia Tan
                </li>
                <li>
                  <FaGamepad />
                  {"\t"}Tristen Hobba
                </li>
                <li>
                  <GiDirectorChair />
                  {"\t"}Talin Roche
                </li>
                <li>
                  <BiFirstPage />
                  <FaGamepad />
                  {"\t"}Stephen Lowe
                </li>
                <li>
                  <FaGamepad />
                  {"\t"}Daniel Wong
                </li>
                <li>
                  <FaGamepad />
                  <GiCardRandom />
                  {"\t"}Jack Spiggle
                </li>
              </ul>
              <div
                style={{
                  flex: 1,
                  backgroundColor: "black",
                  height: "2px",
                  opacity: "15%",
                }}
              />
            </div>
            <div style={{ ...box_style, margin: 10, marginTop: 0 }}>
              <h2>Initial Playtesters</h2>
              <p style={{ padding: 10, textAlign: "center" }}>
                These people helped test the rules and crease out any broken
                game mechanics <BiTestTube />
              </p>

              <ul>
                <li>Jarod Reynolds</li>
                <li>Alex Liang</li>
                <li>Zac Hobba</li>
              </ul>
            </div>
            <div
              style={{
                flex: 1,
                backgroundColor: "black",
                height: "2px",
                opacity: "15%",
              }}
            />

            <div
              style={{
                ...box_style,
                margin: 10,
                marginTop: 0,
                textAlign: "center",
              }}
            >
              <h2>Supporters</h2>
              <p>
                Although many supporters did not want a public shout-out, I am
                forever grateful for the support on our recent Kickstarter
                campaign. Thankyou to everyone who participated.
              </p>
              <p>
                <li>Jordan Keefe</li>
                <li>Lightning McQueen - Kachow</li>
                <li>Toshkens - You choose the honk, you get the bonk</li>
                <li>Noah - Keep on Soaring!!</li>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supporters;
