import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSwipeable } from "react-swipeable";
import SlideButton from "./SlideButton";
import { SwipeStyles } from "./LandingPageStyles";
import { slides } from "./SlideContents";

const SwipeModule = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [opacity, setOpacity] = useState(1); // State to control opacity

  const handlePrevSlide = () => {
    setOpacity(0); // Trigger fade out
    setTimeout(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === 0 ? slides.length - 1 : prevSlide - 1,
      );
      setOpacity(1); // Trigger fade in
    }, 200); // Timeout for the transition duration
  };

  const handleNextSlide = () => {
    setOpacity(0); // Trigger fade out
    setTimeout(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1,
      );
      setOpacity(1); // Trigger fade in
    }, 200); // Timeout for the transition duration
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextSlide(),
    onSwipedRight: () => handlePrevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const [dir, setDir] = useState("row");
  const [arrowSize, setArrowSize] = useState("4em");
  const [wSize, setWSize] = useState("80%");
  const [mHeight, setMHeight] = useState("400px");
  const [mAlign, setMAlign] = useState("left");

  useEffect(() => {
    if (isMobile) {
      setDir("column");
      setArrowSize("4em");
      setWSize("100%");
      setMHeight("auto");
      setMAlign("center");
    }
  }, []);

  return (
    <div {...swipeHandlers} style={{ ...SwipeStyles.container, width: wSize }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "space-evenly",
          justifyContent: "space-evenly",
          width: "100%",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "80%",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  ...SwipeStyles.box,
                  height: mHeight,
                  padding: 5,
                  flexDirection: dir,
                  opacity: opacity, // Set opacity
                  position: "relative", // Make the box relative for absolute positioning
                }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: mAlign,
                    position: "relative",
                  }}
                >
                  <div>
                    <p style={SwipeStyles.header}>
                      {slides[currentSlide].title}
                    </p>
                  </div>
                  <div>
                    <p style={SwipeStyles.text}>{slides[currentSlide].text}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <SlideButton
                      link={slides[currentSlide].link}
                      button_text={slides[currentSlide].button_text}
                      bold_text={slides[currentSlide].bold_text}
                    />
                  </div>
                  {isMobile ? null : (
                    <button onClick={handleNextSlide} style={SwipeStyles.arrow}>
                      <span style={{ fontSize: arrowSize, color: "#EC2383" }}>
                        &#8250;
                      </span>
                    </button>
                  )}
                </div>

                <div style={{ width: "100%" }}>
                  <img
                    src={slides[currentSlide].imageUrl}
                    alt={`Slide ${currentSlide}`}
                    style={SwipeStyles.image}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        {slides.map((_, index) => (
          <span
            key={index}
            onClick={() => setCurrentSlide(index)}
            style={{
              ...SwipeStyles.move_icon,
              backgroundColor: currentSlide === index ? "#EC2383" : "#ffffff",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SwipeModule;
