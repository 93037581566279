const PurchaseModuleStyles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
  },
  inputStyle: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    margin: 0,
    padding: 5,
    border: "1px solid #ccc",
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
    fontSize: "12px",
    opacity: "0.85",
  },
  feather: {
    fontSize: "1.5em",
    transition: "transform 0.3s",
  },
  shopButtonHyperlink: {
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
    fontSize: "16px",
    color: "#ed1a84",
    marginTop: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    marginBottom: 0,
  },

  buttonStyle: {
    color: "white",
    backgroundColor: "#EC2383",
    fontSize: 16,
    width: "100%",
    height: "100%",
    border: "none",
    borderRadius: 10,
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
  },

  errorStyle: {
    fontFamily: "Gotham, sans-serif",
    fontWeight: "lighter",
    fontSize: "12px",
    marginTop: 10,
    marginBottom: 0,
  },
};

export { PurchaseModuleStyles };
