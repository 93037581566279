import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import coverphoto from "../images/roost_banner.png";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import {
  FaHome,
  FaPhoneAlt,
  FaShoppingCart,
  FaPeopleCarry,
  FaBook,
} from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { TbChristmasTree } from "react-icons/tb";

const mobile = {
  backgroundColor: "#EC2383",
  flex: "display",
  alignItems: "center",
  justifyContent: "center",
};
const web = {
  backgroundColor: "#EC2383",
  flex: "display",
};

function Navigation() {
  const [navbar, setNavbar] = useState(web);
  const [showBar, setShowBar] = useState(true); // State to toggle visibility of the bar

  useEffect(() => {
    if (isMobile) {
      setNavbar(mobile);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setShowBar(false); // Hide bar if scrolled down
      } else {
        setShowBar(true); // Show bar if at the top
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup
  }, []);

  const christmassMessage = (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f7c9e5",
        color: "#ed1a84",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TbChristmasTree />

      <div
        style={{
          textAlign: "center",
          padding: "5px",
          fontFamily: "Gotham, sans-serif",
          fontSize: "16px",
          top: 0,
          left: 0,
          zIndex: 1100, // Ensure it's above other elements
          cursor: "pointer", // Makes the text appear clickable
        }}
        onClick={() => (window.location.href = "/shop")} // Redirect to the shop page
      >
        <b>Christmas Offer</b> > Free shipping in Australia, Free US shipping on
        2+ copies, and 15% off Roost!
      </div>
      <TbChristmasTree />
    </div>
  );
  return (
    <div className="navigation">
      {showBar && christmassMessage}

      <nav className="navbar navbar-expand navbar-dark" style={navbar}>
        <BrowserView>{browserContents()}</BrowserView>
        <MobileView>{mobileContents()}</MobileView>
      </nav>
    </div>
  );
}

export default Navigation;
function mobileContents() {
  return (
    <div>
      <div>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaHome style={{ width: 40 }} />
              <span className="sr-only">(current)</span>
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/rules"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaGamepad style={{ width: 40 }} />
              Rules
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/purchase"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaPeopleCarry style={{ width: 40 }} />
              Community
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/contact"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaPhoneAlt style={{ width: 40 }} />
              Contact
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/supporters"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaBook style={{ width: 40 }} />
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className="nav-link"
              to="/shop"
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <FaShoppingCart style={{ width: 40 }} />
              Shop
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

function browserContents() {
  return (
    <div
      className="container"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        alignContent: "center",
      }}
    >
      <NavLink className="navbar-brand" to="/">
        <div>
          <img
            class="img-fluid rounded mb-4 mb-lg-0"
            src={coverphoto}
            alt=""
            width={150}
          />
        </div>
      </NavLink>

      <div>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink className="nav-link" to="/">
              Home
              <span className="sr-only">(current)</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/rules">
              Play
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/purchase">
              Community
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to="/contact">
              Contact
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="nav-link" to="/supporters">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/shop">
              Shop
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
