import React from "react";
import { font_families } from "../Styles";
import { HoverTextBox } from "../Modals";

function ContactScreen() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",

          paddingTop: 20,
          fontFamily: font_families.roost,
        }}
      >
        <h2>
          <b>Stay Connected</b>
        </h2>
        <p>
          <i>Links to our social media</i>
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: 20,
        }}
      >
        <a
          href="https://boardgamegeek.com/boardgame/405425/roost"
          target="_blank"
          rel="noreferrer"
        >
          <HoverTextBox text={""} symbol={"boardgamegeek"} />
        </a>

        <a
          href="https://www.instagram.com/roostgame/"
          target="_blank"
          rel="noreferrer"
        >
          <HoverTextBox text={""} symbol={"instagram"} />
        </a>
        <a
          href="https://www.youtube.com/@Roostgame"
          target="_blank"
          rel="noreferrer"
        >
          <HoverTextBox text={""} symbol={"youtube"} />
        </a>
        <a
          href="https://twitter.com/RoostCardGame"
          target="_blank"
          rel="noreferrer"
        >
          <HoverTextBox text={""} symbol={"twitter"} />
        </a>
        <a
          href="https://www.facebook.com/RoostCardGame/"
          target="_blank"
          rel="noreferrer"
        >
          <HoverTextBox text={""} symbol={"facebook"} />
        </a>
      </div>
    </div>
  );
}

export default ContactScreen;
