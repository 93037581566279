import React, { useState, useEffect } from "react";
import page_1 from "../../images/Rules/page_1.png";
import page_2 from "../../images/Rules/page_2.png";
import page_3 from "../../images/Rules/page_3.png";
import page_4 from "../../images/Rules/page_4.png";
import page_5 from "../../images/Rules/page_5.png";
import page_6 from "../../images/Rules/page_6.png";
import page_7 from "../../images/Rules/page_7.png";
import page_8 from "../../images/Rules/page_8.png";
import page_9 from "../../images/Rules/page_9.png";
import page_10 from "../../images/Rules/page_10.png";
import page_11 from "../../images/Rules/page_11.png";
import page_12 from "../../images/Rules/page_12.png";
import page_13 from "../../images/Rules/page_13.png";
import page_14 from "../../images/Rules/page_14.png";
import page_15 from "../../images/Rules/page_15.png";
import page_16 from "../../images/Rules/page_16.png";
import page_17 from "../../images/Rules/page_17.png";
import page_18 from "../../images/Rules/page_18.png";
import page_19 from "../../images/Rules/page_19.png";
import page_20 from "../../images/Rules/page_20.png";
import { useSwipeable } from "react-swipeable";
import { isMobile } from "react-device-detect";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { IconContext } from "react-icons";

export const rules = [
  page_1,
  page_2,
  page_3,
  page_4,
  page_5,
  page_6,
  page_7,
  page_8,
  page_9,
  page_10,
  page_11,
  page_12,
  page_13,
  page_14,
  page_15,
  page_16,
  page_17,
  page_18,
  page_19,
  page_20,
];

function RulesBooklet() {
  const [imgSize, setImgSize] = useState("40%");
  const [index, setIndex] = useState(0);
  const [flipping, setFlipping] = useState(false);

  const slideLeft = () => {
    if (index - 1 < 0) {
      return;
    }

    setFlipping(true);
    setTimeout(() => {
      if (index === 1) {
        setIndex(index - 1);
      } else {
        setIndex(index - 2);
      }
      setFlipping(false);
    }, 300); // Animation duration
  };

  const slideRight = () => {
    if (index + 1 > rules.length - 2) {
      return;
    }

    setFlipping(true);

    setTimeout(() => {
      if (index === 0) {
        setIndex(index + 1);
      } else {
        setIndex(index + 2);
      }
      setFlipping(false);
    }, 300); // Animation duration
  };
  useEffect(() => {
    if (isMobile) {
      setImgSize("50%");
    }
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: slideRight,
    onSwipedRight: slideLeft,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Optional: Allows swiping with a mouse as well
  });

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: 10 }}>
            <IconContext.Provider
              value={{ color: "#EC2383", size: "50px", padding: 50 }}
            >
              <BsFillArrowLeftSquareFill
                onClick={slideLeft}
              ></BsFillArrowLeftSquareFill>
            </IconContext.Provider>
          </div>
          <div style={{ padding: 10 }}>
            <IconContext.Provider
              value={{ color: "#EC2383", size: "50px", padding: 10 }}
            >
              <BsFillArrowRightSquareFill
                onClick={slideRight}
              ></BsFillArrowRightSquareFill>
            </IconContext.Provider>
          </div>
        </div>

        <div
          {...handlers}
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            transition: "transform 0.6s",
            transformStyle: "preserve-3d",
            transform: flipping ? "rotateY(90deg)" : "rotateY(0deg)",
          }}
        >
          {index === 0 || index === rules.length - 1 ? (
            <img
              style={{ width: imgSize, backgroundColor: "white" }}
              src={rules[index]}
              alt="rules page"
            ></img>
          ) : (
            <>
              <img
                style={{
                  width: imgSize,
                  backgroundColor: "white",
                  marginRight: 5,
                }}
                src={rules[index]}
                alt="rules page"
              ></img>
              <img
                style={{
                  width: imgSize,
                  backgroundColor: "white",
                  marginLeft: 5,
                }}
                src={rules[index + 1]}
                alt="rules page"
              ></img>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default RulesBooklet;
