import React from "react";

import { Helmet } from "react-helmet";

function Enquiries() {
  // const [posts, setPosts] = useState([]);
  const emailToCopy = "business@roostgame.com";

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(emailToCopy)
      .then(() => {})
      .catch(() => {});
  };

  return (
    <div className="enquiries" style={{ paddingBottom: 100 }}>
      <Helmet>
        <title>Scorecard - roostgame.com</title>
        <meta
          name="description"
          content="The home of all Roost related updates"
        />
      </Helmet>
      <div className="container">
        <div style={{ width: "100%", padding: 5, paddingTop: 20 }}></div>
        <h1>Contact Us</h1>
        <p style={{ marginBottom: 0 }}>
          <a
            className="e-widget no-button"
            href="/contact"
            rel="nofollow"
            style={{ color: "#000", textDecoration: "none", margin: 0 }}
          >
            Use this <b>form</b> to contact us and gain support.
          </a>
        </p>
        <h1>Shipping Policy</h1>
        <p>
          We are pleased to offer free shipping within Australia on orders over
          $60. Please review the details below to understand our shipping
          process.
        </p>
        <h2>Shipping Rates & Delivery Estimates</h2>
        <ul>
          <li>
            <strong>Free Shipping Australia:</strong> We offer free standard
            shipping within Australia on all orders over <b>$60</b>.
          </li>
          <li>
            <strong>Free Shipping International:</strong> We offer international
            free standard shipping on all orders over <b>$100</b>.
          </li>
          <li>
            <strong>Delivery Time:</strong> Estimated delivery time is 2-10
            business days, depending on your location.
          </li>
          <li>
            <strong>Australia Orders Under $60:</strong> For orders under $60, a
            standard shipping fee will apply, calculated at checkout based on
            your location.
          </li>
          <li>
            <strong>International Orders Under $100:</strong> For International
            orders under $1000, a standard shipping fee will apply, calculated
            at checkout based on your location.
          </li>
          <li>
            <strong>Order Processing:</strong> All orders are processed within
            1-2 business days. Orders are not shipped or delivered on weekends
            or public holidays.
          </li>
          <li>
            <strong>Order Confirmation:</strong> You will receive a confirmation
            email once your order has been placed, and another notification when
            your order has been shipped.
          </li>
          <li>
            <strong>Shipping to P.O. Boxes: </strong>We can ship to P.O. boxes
            within Australia, but please note that some restrictions may apply
            depending on the shipping provider.
          </li>
          <li>
            <strong>Tracking Information:</strong> Once your order has shipped,
            you will receive an email with tracking information so you can
            monitor the delivery status.
          </li>
          <li>
            <strong>Unexpected Delays:</strong> Occasionally, delivery may be
            delayed due to circumstances beyond our control, such as inclement
            weather, postal service delays, or other unforeseen events. We will
            keep you informed of any significant delays.
          </li>
          <li>
            <strong>International Shipping</strong> At this time, we do not
            offer international shipping. We are working on expanding our
            services and hope to offer this in the future.
          </li>
        </ul>
        <h1>Cancellation and Refund Policy</h1>
        <ul>
          <li>
            <b>Contact Us:</b> To initiate a return or exchange, please contact
            our customer support team at{" "}
            <span
              onClick={copyToClipboard}
              style={{ color: "green", cursor: "pointer" }}
            >
              business@roostgame.com
            </span>{" "}
            with your order details and reason for the return/exchange. They
            will guide you through the process and provide you with a return
            shipping address.
          </li>
          <li>
            <b>Timeframe:</b> You have 30 calendar days from the date you
            received your item to initiate a return.
          </li>
          <li>
            <b>Eligibility:</b> To be eligible for a return, your item must be
            unused and in the same condition that you received it. It must also
            be in the original packaging.
          </li>
          <li>
            <b>Proof of Purchase:</b> Please provide a receipt or proof of
            purchase with your return.
          </li>
          <li>
            <b>Processing Time:</b> Once we receive your item, we will inspect
            it and notify you of the status of your refund. If your return is
            approved, we will initiate a refund to your original method of
            payment.
          </li>
          <li>
            <b>Return Shipping Costs:</b> You will be responsible for paying for
            your own shipping costs when returning your item. If you receive a
            refund, the cost of return shipping will be refunded.
          </li>
          <li>
            <b>Damaged or Defective Items:</b> If your item was damaged during
            shipping or is defective, please contact us immediately at{" "}
            <span
              onClick={copyToClipboard}
              style={{ color: "green", cursor: "pointer" }}
            >
              business@roostgame.com
            </span>{" "}
            to arrange for a replacement or refund. In such cases, we will cover
            the return shipping costs.
          </li>
          <li>
            <b>Change of Mind:</b> Please note that we do not offer refunds or
            exchanges for change of mind purchases, finding the product cheaper
            somewhere else, or deciding that you didn&apos;t like the
            purchase/had no use for it. All sales are final unless the product
            is defective or not as described.
          </li>
        </ul>
        <h1>Return Policy</h1>
        <p>
          All sales are final. However, if your product is defective upon
          arrival, reach out to our support team at{" "}
          <span
            onClick={copyToClipboard}
            style={{ color: "green", cursor: "pointer" }}
          >
            business@roostgame.com
          </span>
        </p>
      </div>
    </div>
  );
}

export default Enquiries;
