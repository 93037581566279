/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "Contact",
            "endpoint": "https://9av6xhnoxk.execute-api.ap-southeast-2.amazonaws.com/staging",
            "region": "ap-southeast-2"
        },
        {
            "name": "mailchimp",
            "endpoint": "https://0552ymge06.execute-api.ap-southeast-2.amazonaws.com/staging",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://twd5avfy3fgfjb3ngpjchp5xky.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY"
};


export default awsmobile;
